import { Link, graphql, HeadProps } from "gatsby"
import * as React from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import * as style from "../style/_index.module.scss"
import ProcessingOrg from "../assets/processingorg.jpg"
import CodingTrain from "../assets/codingtrain.jpg"
import Seo from "../components/Seo"

type DataProps = {
    site: {
      siteMetadata: {
        title: string
        description: string
      }
    }
}  

const RecipesPage = ({ data, data: { site } }: PageProps<DataProps>) => {
    return (
        <main>
            <Header></Header>
            <section className={style.sectionRecipesWrap}>
                <div className={style.marginWrap}>
                    <h2>Recipes</h2>
                    <p>これからProcessingを始める方、もっと表現や技術の幅を増やしたい方のため、参考になるサイトを紹介します。</p>
                    <div className={style.gridWrap}>
                        <div className={style.contentWrap}>
                        <a href="https://www.youtube.com/c/TheCodingTrain/featured">
                            <div className={style.image}><img src={CodingTrain} alt /></div>
                            <div className={style.title}>Coding Train</div>
                            <div className={style.description}>クリエイティブコーディングのチュートリアル動画。</div>
                            </a>
                        </div>
                        <div className={style.contentWrap}>
                        <a href="https://processing.org/tutorials/gettingstarted">
                            <div className={style.image}><img src={ProcessingOrg} alt /></div>
                            <div className={style.title}>Processing Getting Started</div>
                            <div className={style.description}>Processing公式サイトのチュートリアル。</div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </main>
    )
}

export default RecipesPage
export const Head = () => (
  <Seo title="レシピ集" />
 )
   

export const query = graphql`
query RecipePageQuery{
  site{
    siteMetadata{
      title
      description
    }
  }
}
`